import React from 'react'
import BOOKING_LINK from '../../../js/BOOKING_LINK'
import { StaticImage } from 'gatsby-plugin-image'

const FreeConsultationCta = () => {
  return (
    <div className="background-colour-beige-3">
      <div className="container py-5 text-center">
        <StaticImage
          alt="little hair lounge logo"
          placeholder="blurred"
          height={200}
          loading="lazy"
          src={'../../../images/lhl-logo-cropped.png'}
        ></StaticImage>
        <h2>Still not sure?</h2>
        <p>
          Book in for a free consultation and together we will work out the best
          plan for your hair.
        </p>
        <a
          href={BOOKING_LINK}
          target="_blank"
          rel="noreferrer"
          className="links"
        >
          Yes Please!
        </a>
      </div>
    </div>
  )
}

export default FreeConsultationCta
